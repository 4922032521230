@import './variables/colors';
@import './fonts';
@import './slick.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './base.scss';
@import './components.scss';
@import './utilities.scss';
