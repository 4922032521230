@layer utilities {
    .text-start {
        @apply text-left;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bec4c4;
        border-radius: 3px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    [dir='rtl'] .text-start {
        @apply text-right;
    }

    .body-link {
        a {
            @apply underline font-bold;
        }
    }

    .line-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .shrink-0 {
        flex-shrink: 0;
    }

    .columns-count-2 {
        column-count: 2;
    }

    .columns-span {
        span {
            padding: 0 0 20px 0;
            display: inline-block;
        }

        :last-child {
            display: inline;
        }
    }

    .stroke-bright {
        stroke: var(--color-romance);
    }

    .rotate-x-180 {
        transform: rotateX(180deg);
    }

    .rotate-y-180 {
        transform: rotateY(180deg);
    }

    .no-scrollbar {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .hyphens-auto {
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }

    .table-cell-p-0 {
        .ant-table-row-level-0 {
            td {
                padding: 0 !important;
            }
        }
    }

    .table-header-cell-dark-bg {
        .ant-table-thead {
            th {
                background-color: #f4f7fc !important;
            }
        }
    }

    .table-header-cell-p-0 {
        .ant-table-thead {
            th {
                padding: 0 !important;
                text-align: center !important;
            }
        }

        .ant-table-filter-column {
            padding: 10px;
        }
    }

    .table-auto-height {
        .ant-table {
            min-height: unset !important;
        }
    }
}

// todo: Ostrovskyi D. - not working in the inside @layer utilities scope - should be checked
.leaflet-marker-icon {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.47));
}

.ant-transfer-operation {
    > button {
        background-color: #a0d3fa;
        border-radius: rounded-full;
    }
}
.ant-transfer {
    > .ant-transfer-operation {
        > .ant-btn {
            display: flex;
            width: 43px;
            height: 43px;
            margin: 5px;
            border-radius: 100px;
            align-items: center;
            justify-content: center;
            // background-color: var(--color-primary)
        }
    }
}

.ant-breadcrumb-separator {
    color: black !important;
}

.ant-checkbox-inner {
    height: 16px !important;
    border-radius: 4px !important;
}

.ant-transfer-list-header-dropdown {
    display: none !important;
}

.ant-checkbox-inner {
    height: 16px !important;
    border-radius: 4px !important;
}
