.ant-table {
    min-height: 405px;
    background-color: transparent !important;

    &-tbody {
        background-color: var(--color-white);
    }

    &-container {
        background-color: var(--color-white);
        border-start-start-radius: 3px;
        border-start-end-radius: 3px;
        filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.08));
    }
}

.ant-tooltip {
    .ant-tooltip-inner {
        background: #f4f5f9;
        color: #1f1f1f;
        border-radius: 3px;
        font-weight: 500;
        font-size: 14px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ant-tooltip-arrow {
        &::before,
        &::after {
            background-color: #eceef4;
        }
    }
}
