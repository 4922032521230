:root {
    --color-primary: #0096ff;
    --color-theme-blue: #0187f9;
    --color-white: #ffffff;
    --color-black: #161616;
    --color-santas-gray: #999aa6;
    --color-charcoal: #414141;
    --color-midnight-express: #252b42;
    --color-flamingo: #f25555;
    --color-ghost-white: #f9f9ff;
    --color-zambezi: #595959;
    --color-nobel: #999999;
    --color-eclipse: #3b3b3b;
    --color-eclipse-gray: rgba(59, 59, 59, 0.6);
    --color-dark-gray: #a6a5a5;
    --color-gray-disabled: rgba(0, 0, 0, 0.25);
    --color-aquamarine: #94f0da;
    --color-tropical-forest: #007b5a;
    --color-marker-green: #20c537;
    --color-varden: #ffefcd;
    --color-dark-goldenrod: #c58600;
    --color-orange-peel: #fda101;
    --color-brink-pink: #ff647c;
    --color-pink: #ffc5cb;
    --color-alizarin: #e0172a;
    --color-quartz: #dfe0eb;
    --color-jaguar: #292931;
    --color-whisper: #ebebeb;
    --color-theme-whisper: #e9eff8;
    --color-solitude: #eceef4;
    --color-radical-red: #ff4b5c;
    --color-white-smoke: #f7f7f7;
    --color-transparent-white: rgba(255, 255, 255, 0.2);
    --color-granite-gray: rgba(102, 102, 102, 0.08);
    --color-overlay: rgba(102, 102, 102, 0.4);
    --color-cultured: #f4f5f9;
    --color-independence: #646348;
    --color-eerie: #1a1a1a;
    --color-nero: #1f1f1f;
    --color-manatee: #a59f9c;
    --color-faded-sea: #919cb0;
    --color-wild-sand: #f5f5f5;
    --color-green-status: #36b37e;
    --color-red-status: #ff5630;
    --color-yellow-status: #ffe380;
    --color-grey-status: #c1c7d0;
    --color-orange-status: #fda101;
    --color-blue-status: #0187f9;
    --color-divider: #cccccc;
}
