.ep-table-pagination {
    margin-top: 11px;

    .ep-table-pagination-prev,
    .ep-table-pagination-next {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-white);
        border: none;
        border-radius: 3px;
    }

    .ep-table-pagination-prev {
        margin-inline-end: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .ep-table-pagination-next {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .ep-table-pagination-item {
        margin-inline-end: 3px;
        border-radius: 0;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: var(--color-white);
        color: var(--color-eclipse);

        &-active {
            background: var(--color-primary);

            > a {
                color: var(--color-white) !important;
            }
        }

        &-ellipsis {
            color: var(--color-eclipse) !important;
            letter-spacing: 0px !important;
            text-indent: 0 !important;
            top: 2px !important;
        }
    }

    .ep-table-pagination-jump-next,
    .ep-table-pagination-jump-prev {
        margin-inline-end: 3px;
        background: var(--color-white);
        border-radius: 0;
    }

    &-options .ant-select-dropdown {
        top: -172px !important;
    }
}
