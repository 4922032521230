$fontPath: '../fonts/';

@mixin font-face($family, $fileName, $weight: normal, $style: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$fontPath}#{$fileName}.woff2') format('woff2');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

@include font-face('Inter', 'Inter-Regular', 400);
@include font-face('Inter', 'Inter-Medium', 500);
@include font-face('Inter', 'Inter-SemiBold', 600);
@include font-face('Inter', 'Inter-Bold', 700);

:root {
    --font-family: Inter, Helvetica, Arial, sans-serif;
}
