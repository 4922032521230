.ep-form-item-select-selector {
    min-height: 48px;
    justify-content: center;
    align-items: center;
    input {
        min-height: 48px;
    }
}

.ep-form-item-radio-group {
    display: flex;
    height: 48px !important;
    width: 100%;
    label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.ep-form-item-timepicker-ok > button {
    background-color: var(--color-primary);
}

.ep-form-item-timepicker-input > input {
    font-size: 16px !important;
}
